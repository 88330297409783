import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Description from "../../../texts/Description";
import Content from "../../../layout/Content/Content";
import HomeBannerIllustration from "../../home/HomeBannerIlustration";
import Button from "../../../buttons/Button";
// @ts-ignore
import Headline from "../../../texts/Headline";
import MediaQuery from "../../../layout/MediaQuery";

const Container = Styled.div`
  margin: 15rem 0 10rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("medium")`
    margin: 20rem 0 15rem 0;
  `}
`;

const Columns = Styled.div`
  flex-direction: column;
`;

const Column = Styled.div`
  text-align: center;
`;

const ColumnText = Styled(Column)`
  margin-bottom: 4rem;
  padding-right: 0;

  ${Media.greaterThan("large")`
    margin-bottom: 0;
  `}
`;

const ColumnIllustration = Styled(Column)`
  margin-top: 5rem;

  ${Media.greaterThan("medium")`
    margin-top: 10rem;
  `}
`;

const Header = Styled.div`
  max-width: 100%;
  margin: 0 auto;
`;

const HeadlineWrapper = Styled.div`
  max-width: 100%;
  margin: 0;
  
  svg {
    max-width: 100%;
  }

  ${Media.greaterThan("medium")`
    margin: 0
  `}
`;

const DescriptionExtended = Styled(Description)`
  width: 28.5rem;
  margin: 2rem auto 3rem auto;

  ${Media.greaterThan("700px")`
    width: auto;
    margin-bottom: 3rem;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
  text-align: center;
  font-size: 3.6rem;
  line-height: 3.8rem;
  max-width: 100%;
  margin: 0 0 2rem 0;
  
  ${Media.greaterThan("medium")`
    width: auto;
    margin: 0 0 3rem 0;
  `}

  ${Media.greaterThan("large")`
    font-size: 5.6rem;
    line-height: 6.5rem;
  `}
`;

const w = 1920;
const h = 1020;

const PlayButton = Styled.div`
  box-sizing: border-box;
  width: 74px;
  height: 74px;
  border-style: solid;
  border-width: 37px 0px 37px 74px;
  border-color: transparent transparent transparent #030303;
  position: absolute;
  top: calc(50% - 37px);
  left: calc(50% - 37px);
  z-index: 10000;
  opacity: 1;
`;

const ImageA = Styled(Image).attrs({
  style: {
    position: "absolute",
  },
})`
  position: absolute;
  width: 100%;
  z-index: 0;
  opacity: 0.75;
`;

const VideoContainer = Styled.a`
  display: block;
  position: relative;
  width: calc(${w}px / 2);
  max-width: 100%;
  padding-bottom: ${Math.round((h / w) * 75)}%;
  margin: 0 auto;
  box-shadow: 0px 2px 20px rgba(3,3,3,0.1);
  border-radius: 30px;
  overflow: hidden;

  &:hover ${PlayButton} {
    opacity: 0.75;
  }

  &:hover ${ImageA} {
    opacity: 1;
  }
`;

const PHImage = Styled.img`
  width: 250px;
  height: 54px;
`;

type BannerProps = {
  imageA: GatsbyImageProps;
};

const HeaderBanner = ({ imageA }: BannerProps) => (
  <Container>
    <Content>
      <Columns>
        <ColumnText>
          <Header>
            <HeadlineWrapper>
              <ExtendedHeadline as="h1">Uizard Hat 1.0</ExtendedHeadline>
            </HeadlineWrapper>

            <DescriptionExtended>
              Unleash the power of true design thinking
            </DescriptionExtended>

            <a
              href="https://www.producthunt.com/posts/uizard-hat-1-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-uizard&#0045;hat&#0045;1&#0045;0"
              target="_blank"
            >
              <PHImage
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=338629&theme=dark"
                alt="Uizard&#0032;Hat&#0032;1&#0046;0 - Design&#0032;apps&#0032;and&#0032;websites&#0032;with&#0032;your&#0032;thoughts | Product Hunt"
                width="250"
                height="54"
              />
            </a>
          </Header>
        </ColumnText>

        <ColumnIllustration>
          <VideoContainer href="https://youtu.be/4pb1aOLZ2Kk" target="_blank">
            <PlayButton />
            <ImageA
              fluid={"fluid" in imageA ? imageA.fluid : null}
              alt="Hero image showing Uizard Hat 1.0 video cover."
            />
          </VideoContainer>
        </ColumnIllustration>
      </Columns>
    </Content>
  </Container>
);

export default HeaderBanner;
