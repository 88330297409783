import * as React from "react";
import constants from "constants/index";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import HomeConversion from "../../components/pages/home/HomeConversion";
import HomeTemplates from "../../components/pages/home/HomeTemplates";
import HomePrototyping from "../../components/pages/home/HomePrototyping";
import HomeFeedback from "../../components/pages/home/HomeFeedback";
import HomeStyling from "../../components/pages/home/HomeStyling";
import HomeIdeation from "../../components/pages/home/HomeIdeation";
import HomeWireframe from "../../components/pages/home/HomeWireframe";
import HomeBrainstorming from "../../components/pages/home/HomeBrainstorming";
import HeaderBanner from "../../components/pages/uizard-hat/HeaderBanner";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import Shoutout from "../../components/pages/signup/Shoutout";
import Content from "../../components/layout/Content/Content";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Home = ({ data }) => (
  <Layout>
    <Meta
      title="Introducing Uizard Hat 1.0 | Uizard"
      description="The final barrier between idea and reality has finally been conquered. From thought, straight into prototype. Discover the Uizard Hat 1.0."
      url="/uizard-hat/"
    />

    <HeaderBanner imageA={data.bannerImageA.childImageSharp} />

    <HomePrototyping
      headline="Create design mockups straight from your mind."
      description="The final barrier between idea and reality has finally been conquered. From thought, straight into prototype. 
      This is not a wearable noninvasive EEG controller, this is better! Unleash the power of true design thinking."
      imageA={data.powerOfDesignThinkingA.childImageSharp}
      imageB={data.powerOfDesignThinkingB.childImageSharp}
      imageAlt="Unleash the power of design thinking image."
    />

    <HomeConversion
      headline="We don't always joke on April Fools' Day. Just kidding, we do."
      description="🧙 Uizard Hat 1.0 is just a joke for now, but we have some other auto-magic design features which are real. Why don't you give it a go?"
      imageA={data.uizardHatA.childImageSharp}
      imageB={data.uizardHatB.childImageSharp}
      imageAlt="Uizard Hat 1.0 product image."
      link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
      cta="Try Uizard for free"
    />

    <ContentExtended>
      <Shoutout
        headline="Design made easy"
        description="Any sufficiently advanced technology is indistinguishable from magic. And Uizard is pretty magical!"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(
      relativePath: { eq: "uizard-hat/uizard-hat-video-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    powerOfDesignThinkingA: file(
      relativePath: {
        eq: "uizard-hat/unleash-the-power-of-design-thinking-a.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 882, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    powerOfDesignThinkingB: file(
      relativePath: {
        eq: "uizard-hat/unleash-the-power-of-design-thinking-b.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 624, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    uizardHatA: file(relativePath: { eq: "uizard-hat/uizard-hat-1.0-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 938, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    uizardHatB: file(relativePath: { eq: "uizard-hat/uizard-hat-1.0-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
